console.log('main.es6.js...');
// A $( document ).ready() block.
$( document ).ready(function() {
	console.log( "ready!" );
	// variables
	var duration = 4000;
	var zIndex = 10;
	var openImages = [];
	var illustrations = [
	'https://gobber.co/images/illustrations/gobberco_illu-antonio-christoph.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-titbit-02.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-sublime.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-tic-toc.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-giulia.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-pavel.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-titbit-01.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-invert.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-starrrecordings.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-sogni-di-una-donna.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-mentally-jelly.jpg',
	'https://gobber.co/images/illustrations/gobberco_illu-dreaming-alive.jpg'
	];
	function getIllustration(i) {
		console.log(illustrations.length); 
		// return illustrations[Math.floor(Math.random() * illustrations.length)];
		return illustrations[i];
	}
	// functions
	function toggleVideo(dataSrc, text, offset) {
		return;
	}
	function toggleImage(dataSrc, text, classes, offset) {
		var width = $(window).width();
		var dataTop = $(document).scrollTop();
		if (width >= 1200) {
			dataTop += 180;
			console.log('bigger than 1200');
		}
		else if (width >= 800 < 1200) {
			dataTop += 90;
			console.log('betwee 800 and 1200');
		}
		if ( offset !== undefined )
			dataTop = $(document).scrollTop() + offset;

		console.log(offset, width, dataTop);
		// check if it is already open
		// else push in array
		if ($.inArray(dataSrc, openImages) !== -1) {
			console.log('YES, in array');
			var myImg = $('img[src="' + dataSrc + '"]');
			myImg.toggleClass('js--hide').attr('style', 'top:' + dataTop + 'px; z-index:' + zIndex + ';');
			zIndex++;
			var removeImg = setTimeout(function(){
				myImg.toggleClass('js--hide');
				$('.no--events').toggleClass('no--events');
			}, duration - 100);
		}
		else {
			console.log('NOT in array');
			openImages.push(dataSrc);
			console.log(text, dataSrc, dataTop);
			var myImg = $('<img />', {
				class: 'overlay--image ' + classes,
				src: dataSrc,
				alt: text, 
				style: 'top:' + dataTop + 'px; z-index:' + zIndex + ';'
			});
			$('.overlay--inside').append(myImg);
			zIndex++;
			myImg.addClass('remove--animation');
			var removeImg = setTimeout(function(){
				myImg.toggleClass('js--hide');
				$('.no--events').toggleClass('no--events');
			}, duration - 100);
		}
	}

  // toggle grid
  $('.js--toggle-grid').on('click', function(e) {
  	console.log('grid it');
  	e.preventDefault();
  	$('body').toggleClass('grid');
  });
  // $('a[title=js--font-toggle]').on('click', function(e) {
  // 	console.log('font it');
  // 	e.preventDefault();
  // 	$('body').toggleClass('js--font-toggle');
  // });

  // toggle passionate-illustrator
  var jsToggleIllustrationCounter = 0;
  $('.js--toggle-illustration').on('click', function(e){
  	e.preventDefault();
  	// $(this).toggleClass('no--events');
  	// random illustrations of alberto gobber
  	console.log( jsToggleIllustrationCounter );
  	if ( jsToggleIllustrationCounter == illustrations.length ) jsToggleIllustrationCounter = jsToggleIllustrationCounter - illustrations.length;
  	var dataSrc = getIllustration(jsToggleIllustrationCounter);
  	jsToggleIllustrationCounter++;
  	var text = $(this).parent().text();
  	toggleImage(dataSrc, text, 'illustrations', 0); 	
  });
  // toggle images
  $('.proj-list li img').on('click', function(){
  	$(this).toggleClass('no--events');
  	// clean up the style attribute
  	// remove spaces and remove semicolon at end
  	var dataSrc = $(this).attr('src').replace('_thumb','');
  	var text = $(this).parent().text();
  	var isVideo = $(this).attr('alt');
  	if (isVideo == 'video') {
  		toggleVideo(dataSrc, text); 
  	}
  	else {
  		toggleImage(dataSrc, text, 'proj'); 		
  	}	
  });

  // toggle tooltip
  $('.js--toggle-illustration, .js--toggle-grid').hover(
  	// mouseenter
  	function(){
  		// var tooltip = '<span class="tooltip">' + $(this).attr('title') + '</span>';
  		// $('body').append(tooltip);
  		var h1Height = $('h1').outerHeight();
  		$('h1').attr('style', 'height: ' + h1Height + 'px;').html('&nbsp;<span>' + $(this).attr('title') + '</span>');
  	},
  	// mouseleave
  	function() {
  		console.log('mouseout');
  		// $('.tooltip').remove();
  		$('h1').html('Alberto Gobber'); 
  	});
});